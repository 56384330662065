import React, { useState, useEffect } from "react";
import { Web3Button } from "@web3modal/react";
import { useAccount, useNetwork, useSignMessage } from "wagmi";
import dayjs from "dayjs";
import { solNum, getSortCode, callTXLink } from "../utils/functions";
const sleep = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
const Tuf = ({
	claimTuf,
	checkTUFTokens,
	airdrop,
	tufHistory,
	claimTUFSession,
}) => {
	const { chain, chains } = useNetwork();
	const { address, isConnected } = useAccount();
	const [message, setMessage] = useState("");
	const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage(
		{
			message,
			onSuccess(data) {
				setSignature(data);
				claimTuf(data, address, session);
			},
			onError(error) {
				console.log("error", error);
				//window.location.reload();
			},
		}
	);

	const [signature, setSignature] = useState();
	const [session, setSession] = useState();

	useEffect(() => {
		if (isConnected && !!address) {
			checkTUFTokens(address);
		}
	}, [address, chain]);
	const claimTufStart = async () => {
		try {
			const session = await claimTUFSession(address);
			if (!!!session) throw "error";
			setSession(session);
			const m =
				"Please sing this message to be able to claim TUF airdrops.\n\n\n" +
				session;
			setMessage(m);
			signMessage({ message: m });
		} catch (error) {
			console.log("claimTufStart", error);
		}
	};
	return (
		<div className="tuf-dashboard">
			<div className="dashboard-wrapper">
				<div className="dashboard-left">
					<span>
						$TUF holders who held from the snapshot agreement,
						through relaunch, are eligible to claim $RS tokens*.
						Initial value is a 10% bonus from the snapshot, but
						token supply is smaller. Connect your $TUF eth wallet to
						see if you are eligible.
					</span>
					<br />
					<i style={{ fontSize: "14px" }}>
						*You must hold $TUF tokens until you claim or the
						agreement is void.
					</i>
					<div className="eth-wallet">
						<Web3Button icon="hide" label="Check Eligibility" />
					</div>
				</div>
				<div className="dashboard-right">
					<div className="user-information-row">
						<span className="text">Unclaimed $TUF</span>
						<span className="number">
							{!!airdrop ? solNum(airdrop) + " $RS" : "--"}
						</span>
					</div>
					{!!airdrop && (
						<button id="claim-button" onClick={claimTufStart}>
							Claim
						</button>
					)}
				</div>
			</div>
			<div className="claim-history">
				<h2>Claim History</h2>
				<div className="claim-history-table">
					<div className="table-row title">
						<span>Time</span>
						<span>Amount</span>
						<span>Transaction</span>
					</div>
					{tufHistory.map((m, i) => (
						<div className="table-row" key={i}>
							<span>{dayjs(m.ts).format("MMM D HH:mm:ss")}</span>
							<span>
								{!!m.tokens ? solNum(m.tokens) + " $RS" : "--"}
							</span>
							<span
								onClick={(e) => {
									e.stopPropagation();
									callTXLink("ETH", m.txhash);
								}}
								className="learn-more"
							>
								{getSortCode(m.txhash)}
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Tuf;
