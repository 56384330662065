const CryptoJS = require("crypto-js");

const _network = {
	ETH: "https://etherscan.io/",
	BSC: "https://bscscan.com/",
	SOL: "https://solscan.io/tx/",
};

const getSortCode = (code) => {
	if (!!code) {
		code = code.toLowerCase();
		return `${code[0]}${code[1]}${code[2]}${code[3]}${code[4]}${
			code[5]
		}...${code[code.length - 4]}${code[code.length - 3]}${
			code[code.length - 2]
		}${code[code.length - 1]}`;
	}
	return "";
};

const callLink = (network, address) => {
	window.open(
		`${_network[network]}address/${address.toLowerCase()}`,
		"_blank"
	);
};
const callTXLink = (network, address) => {
	window.open(`${_network[network]}tx/${address.toLowerCase()}`, "_blank");
};
const callNewSite = (link) => {
	window.open(`${link}`, "_blank");
};
const solNum = (num) => {
	try {
		const a = parseFloat(num)
			.toFixed(4)
			.replace(/\.?0+$/, "");

		const formattedNumberUS = parseFloat(a).toLocaleString("en-US", {
			minimumFractionDigits: 0,
			maximumFractionDigits: 4,
		});

		return formattedNumberUS;
	} catch (error) {
		console.log("error", error);
		return 0;
	}
};
export { getSortCode, callLink, callTXLink, callNewSite, solNum };
