import { useState, useEffect } from "react";
import logo from "./media/logo.png";
import axiosI from "./api/api";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { toast } from "react-toastify";
import RevShare from "./components/RevShare";
import Referral from "./components/Referral";
import Tuf from "./components/Tuf";
import { Web3Modal, useWeb3ModalTheme } from "@web3modal/react";
const sleep = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
const baseRevData = {
	address: "",
	tokens: "",
	boost: 0,
	sol: "",
	all_sol: "",
	all_claimed: "",
	total_claimed: "",
	rev_share_perc: "",
};
let intervalSID;
function App() {
	const [session, setSession] = useState("");
	const [menu, setMenu] = useState(3);
	const [activeClass, setActiveClass] = useState("active active_tuf");
	const { connected, publicKey, signMessage } = useWallet();

	const [revData, setRevData] = useState(baseRevData);
	const [revHistory, setRevHistory] = useState([]);
	const [tufHistory, setTufHistory] = useState([]);
	const [airdrop, setAirdrop] = useState();

	const { theme, setTheme } = useWeb3ModalTheme();
	useEffect(() => {
		setTheme({
			themeMode: "dark",
			themeVariables: {
				"--w3m-font-family": "Poppins, sans-serif",
				"--w3m-accent-color": "#30bd92",
				"--w3m-background-color": "#1a1a1a",
				"--w3m-z-index": "9999",
			},
		});
	}, []);
	useEffect(() => {
		if (!!publicKey) {
			auth_session();
		} else {
			if (!!!intervalSID) {
				clearInterval(intervalSID);
			}
		}
	}, [publicKey]);
	useEffect(() => {
		if (!!menu && !!connected && !!session) {
			//signAuthMessage();

			getWalletData();
		}
	}, [connected, menu]);

	const getWalletData = () => {
		switch (menu) {
			case 1:
				getRevenueData();
				getRevHistoryData();
				break;
			case 2:
				getReferralData();
				break;
			case 3:
				getTufData();
				break;

			default:
				break;
		}
	};
	const ping = () => {
		//auth_sid();
		intervalSID = setInterval(() => {
			auth_sid();
		}, 12 * 1000);
	};
	const auth_session = () => {
		if (!!!publicKey) return;
		axiosI
			.post("/api/session", {
				publicKey: publicKey.toBase58(),
			})
			.then(async (res) => {
				try {
					setSession(res.data.data);
					await auth_sid();
					ping();

					getWalletData();
				} catch (error) {}
			})
			.catch((error) => {
				setSession("");
				try {
					//toast.error(error.response.data.error.message);
				} catch (err) {
					toast.error("Network error! Please refresh the site.");
				}
			});
	};
	const auth_sid = async () => {
		try {
			if (!!!publicKey) return;
			await axiosI.post("/api/sid", {
				publicKey: publicKey.toBase58(),
			});
			return true;
		} catch (error) {}
	};
	const getRevenueData = () => {
		if (!!!publicKey) return;
		axiosI
			.post("/api/get_revenue_data", {
				publicKey: publicKey.toBase58(),
			})
			.then((res) => {
				try {
					setRevData(res.data.data);
				} catch (error) {}
			})
			.catch(async (error) => {
				setRevData(baseRevData);
				try {
					if (!!error.response.data) {
						if (error.response.data.error.code == 9) {
							toast.error(
								"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
							);
						} else if (error.response.data.error.code == 5) {
							toast.error(
								"Network error! Please refresh the site."
							);
						} else {
							//toast.error(error.response.data.error.message);
						}
					}
				} catch (err) {
					toast.error("Network error! Please refresh the site.");
				}
			});
	};
	const getRevHistoryData = () => {
		if (!!!publicKey) return;
		axiosI
			.post("/api/get_rev_history", {
				publicKey: publicKey.toBase58(),
			})
			.then((res) => {
				try {
					setRevHistory(res.data.data);
				} catch (error) {}
			})
			.catch(async (error) => {
				setRevHistory([]);
				try {
					if (!!error.response.data) {
						if (error.response.data.error.code == 9) {
							toast.error(
								"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
							);
						} else if (error.response.data.error.code == 5) {
							toast.error(
								"Network error! Please refresh the site."
							);
						} else {
							//toast.error(error.response.data.error.message);
						}
					}
				} catch (err) {
					toast.error("Network error! Please refresh the site.");
				}
			});
	};
	const getReferralData = () => {
		if (!!!publicKey) return;
		axiosI
			.post("/api/get_referral_data", {
				publicKey: publicKey.toBase58(),
			})
			.then((res) => {
				try {
				} catch (error) {}
			})
			.catch(async (error) => {
				try {
					if (!!error.response.data) {
						if (error.response.data.error.code == 9) {
							toast.error(
								"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
							);
						} else if (error.response.data.error.code == 5) {
							toast.error(
								"Network error! Please refresh the site."
							);
						} else {
							//toast.error(error.response.data.error.message);
						}
					}
				} catch (err) {
					toast.error("Network error! Please refresh the site.");
				}
			});
	};
	const getTufData = () => {
		/* axiosI
			.post("/api/get_tuf_data", {
				publicKey: publicKey.toBase58(),
			})
			.then((res) => {
				try {
					console.log("res", res);
				} catch (error) {}
			})
			.catch(async (error) => {
				if (!!error.response.data) {
					if (error.response.data.error.code == 9) {
						toast.error(
							"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
						);
					} else if (error.response.data.error.code == 5) {
						toast.error("Network error! Please refresh the site.");
					}
				}
			}); */
	};

	const claimRev = async () => {
		try {
			if (!!!publicKey) return;
			const sessionS = await axiosI.post("/api/claim_session", {
				publicKey: publicKey.toBase58(),
			});
			let claimSession = sessionS.data.data;
			if (!!!claimSession) throw "Session error";

			const signature = await signAuthMessage(claimSession);
			if (!!!signature) throw "signature error";

			axiosI
				.post("/api/claim_rev", {
					publicKey: publicKey.toBase58(),
					session: claimSession,
					signature,
				})
				.then((res) => {
					try {
						toast.success("Success Claim");
						getWalletData();
					} catch (error) {}
				})
				.catch(async (error) => {
					try {
						if (!!error.response.data) {
							if (error.response.data.error.code == 9) {
								toast.error(
									"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
								);
							} else if (error.response.data.error.code == 5) {
								toast.error(
									"Network error! Please refresh the site."
								);
							} else {
								toast.error(error.response.data.error.message);
							}
						}
					} catch (err) {
						toast.error("Network error! Please refresh the site.");
					}
				});
		} catch (error) {
			toast.error("Network error! Please refresh the site.");
		}
	};
	const signAuthMessage = async (session) => {
		const message = new TextEncoder().encode(
			"Please sing this message to be able to claim your Revenue shares.\n\n\n" +
				session
		);
		try {
			const signature = await signMessage(message);
			return signature;
		} catch (error) {
			console.error("Signing failed", error);
			return false;
		}
	};

	/**TUF */
	const checkTUFTokens = (address) => {
		if (!!!publicKey || !!!address) return;
		axiosI
			.post("/api/tuf", {
				publicKey: publicKey.toBase58(),
				wallet: address,
			})
			.then((res) => {
				try {
					//console.log("tuf", res.data.data);
					setAirdrop(res.data.data);
				} catch (error) {}
			})
			.catch(async (error) => {
				setAirdrop();
				try {
					if (!!error.response.data) {
						if (error.response.data.error.code == 9) {
							toast.error(
								"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
							);
						} else if (error.response.data.error.code == 5) {
							toast.error(
								"Network error! Please refresh the site."
							);
						} else {
							//toast.error(error.response.data.error.message);
						}
					}
				} catch (err) {
					toast.error("Network error! Please refresh the site.");
				}
			});
		getTufHistoryData(address);
	};
	const getTufHistoryData = (address) => {
		if (!!!publicKey || !!!address) return;
		axiosI
			.post("/api/get_tuf_history", {
				publicKey: publicKey.toBase58(),
				wallet: address,
			})
			.then((res) => {
				try {
					setTufHistory(res.data.data);
				} catch (error) {}
			})
			.catch(async (error) => {
				setTufHistory([]);
				try {
					if (!!error.response.data) {
						if (error.response.data.error.code == 9) {
							toast.error(
								"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
							);
						} else if (error.response.data.error.code == 5) {
							toast.error(
								"Network error! Please refresh the site."
							);
						} else {
							//toast.error(error.response.data.error.message);
						}
					}
				} catch (err) {
					toast.error("Network error! Please refresh the site.");
				}
			});
	};
	const claimTUFSession = async (address) => {
		try {
			if (!!!publicKey || !!!address) return;
			const sessionS = await axiosI.post("/api/claim_tuf_session", {
				publicKey: publicKey.toBase58(),
				wallet: address,
			});
			let claimSession = sessionS.data.data;
			if (!!!claimSession) throw "Session error";

			return claimSession;
		} catch (error) {
			toast.error("Network error! Please refresh the site.");
			return false;
		}
	};
	const claimTuf = async (signature, address, session) => {
		try {
			if (!!!publicKey || !!!address) return;
			axiosI
				.post("/api/claim_tuf", {
					publicKey: publicKey.toBase58(),
					session: session,
					signature,
					wallet: address,
				})
				.then((res) => {
					try {
						toast.success("Success Claim");
						checkTUFTokens(address);
					} catch (error) {}
				})
				.catch(async (error) => {
					try {
						if (!!error.response.data) {
							if (error.response.data.error.code == 9) {
								toast.error(
									"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
								);
							} else if (error.response.data.error.code == 5) {
								toast.error(
									"Network error! Please refresh the site."
								);
							} else {
								toast.error(error.response.data.error.message);
							}
						}
					} catch (err) {
						toast.error("Network error! Please refresh the site.");
					}
				});
		} catch (error) {
			toast.error("Network error! Please refresh the site.");
			return false;
		}
	};

	const clickMenu = (menu_) => {
		setMenu(menu_);
		switch (menu_) {
			case 1:
				setActiveClass("active active_revenue");
				break;
			case 2:
				setActiveClass("active active_referral");
				break;
			case 3:
				setActiveClass("active active_tuf");
				break;

			default:
				setActiveClass("active active_revenue");
				break;
		}
	};

	return (
		<>
			<div
				className="header-wrapper"
				data-aos="fade-down"
				data-aos-duration="1300"
				style={{ position: "relative" }}
			>
				<header>
					<a
						href="https://readyswap.io"
						className="header-logo-wrapper"
					>
						<img className="header-logo" src={logo} />
						<div className="readyswap">
							Ready<span className="smaller">Swap</span>
						</div>
					</a>
					<div className="wallet">
						<WalletMultiButton
							labels={{ "no-wallet": "Connect Wallet" }}
						/>
					</div>
				</header>
			</div>
			<section className="switch-button-wrapper">
				<div className="switch-button">
					<span className={activeClass}></span>
					<button
						className={
							menu === 1
								? "switch-button-case left active-case "
								: "switch-button-case left"
						}
						style={{ color: "grey" }}
					>
						Revenue Share
					</button>

					<button
						className={
							menu === 2
								? "switch-button-case middle active-case "
								: "switch-button-case middle"
						}
						style={{ color: "grey" }}
					>
						Referral Rewards
					</button>

					<button
						className={
							menu === 3
								? "switch-button-case right active-case "
								: "switch-button-case right"
						}
						onClick={() => clickMenu(3)}
					>
						$TUF Airdrop Claim
					</button>
				</div>
			</section>
			<section>
				{menu === 1 && (
					<RevShare
						revData={revData}
						revHistory={revHistory}
						claimRev={claimRev}
					/>
				)}
				{menu === 2 && <Referral />}
				{menu === 3 && (
					<Tuf
						checkTUFTokens={checkTUFTokens}
						airdrop={airdrop}
						tufHistory={tufHistory}
						claimTUFSession={claimTUFSession}
						claimTuf={claimTuf}
					/>
				)}
			</section>
		</>
	);
}

export default App;
