import React from "react";
import dayjs from "dayjs";
import arrow from "../media/up-right-arrow.png";
import { solNum, getSortCode, callTXLink } from "../utils/functions";
const RevShare = ({ revData, revHistory, claimRev }) => {
	return (
		<div className="revenue-sharing">
			<div className="dashboard-top">
				<div className="information">
					<span className="text">Current Rev Share %</span>
					<span className="number">
						{!!revData.rev_share_perc
							? revData.rev_share_perc + "%"
							: "--"}
					</span>
				</div>
				<div className="information">
					<span className="text">Total Rewards Claimed</span>
					<span className="number">
						{!!revData.total_claimed
							? solNum(revData.total_claimed)
							: "--"}
					</span>
				</div>
			</div>
			<div className="dashboard-wrapper">
				<div className="dashboard-left">
					<h1>Revenue Sharing</h1>
					<span>
						You must hold at least 10 $RS to be eligible for rev
						share. Rewards are proportional to your holdings and are
						calculated every 24 hours. Any sell or transfer within
						the 24 hour calculation period results in forfeiture of
						rewards for that 24 hour period. Rewards are claimable
						after every 24 hour distribution.
					</span>
					<div className="divider"></div>
					<h1>Timed Weighting Boost</h1>
					<span>
						The weighting of your holdings increase by 5% daily, for
						up to 100 days, to get a max of 500% weighting boost.
						This increases your share of the pie of revenue share
						the longer you hold. Any transfer or selling of tokens
						result in resetting your boost.
					</span>
					<a
						href="https://docs.readyswap.io/readyswap-token/revenue-sharing/timed-weighting-boost"
						className="learn-more"
					>
						Learn More <img className="arrow" src={arrow} />
					</a>
				</div>
				<div className="dashboard-right">
					<div className="user-information-row">
						<span className="text">Lifetime Claimed Rewards</span>
						<span className="number">
							{!!revData.all_claimed
								? solNum(revData.all_claimed)
								: "--"}
						</span>
					</div>
					<div className="user-information-row">
						<span className="text">Unclaimed Rewards</span>
						<span className="number">
							{!!revData.sol
								? solNum(revData.sol) + " SOL"
								: "--"}
						</span>
					</div>
					<div className="user-information-row">
						<span className="text">
							Current Timed Weighting Boost
						</span>
						<span className="number">
							{!!revData.boost ? revData.boost + "%" : "0"}
						</span>
					</div>
					{!!revData.sol && (
						<button id="claim-button" onClick={claimRev}>
							Claim
						</button>
					)}
				</div>
			</div>
			<div className="claim-history">
				<h2>Claim History</h2>
				<div className="claim-history-table">
					<div className="table-row title">
						<span>Time</span>
						<span>Amount</span>
						<span>Transaction</span>
					</div>
					{revHistory.map((m, i) => (
						<div className="table-row" key={i}>
							<span>{dayjs(m.ts).format("MMM D HH:mm:ss")}</span>
							<span>
								{!!m.sol ? solNum(m.sol) + " SOL" : "--"}
							</span>
							<span
								onClick={(e) => {
									e.stopPropagation();
									callTXLink("SOL", m.txhash);
								}}
								className="learn-more"
							>
								{getSortCode(m.txhash)}
							</span>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default RevShare;
